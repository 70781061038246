export const calculateUnits = (total: number, price: number, precision: number) => {
  return (total / price).toFixed(precision);
};
  
export const calculateTotal = (units: number, price: number, precision: number) => {
  return (units * price).toFixed(precision);
};

export function formatByPrecisionAndTrim(
  value: string,
  precision: number,
  min: number,
  max: number,
): string {
  
  if (!value) return '0';
  if (isNaN(max)) return value;
  if (isNaN(min)) return value;
  if (isNaN(Number(value))) return '0';
  if (!precision || precision < 0) return value;
  
  let formattedValue = value.replace(/[^\d.]/g, '');
  const parts = formattedValue.split('.');
  formattedValue = parts[0] + (parts.length > 1 ? '.' + parts.slice(1).join('') : '');
  formattedValue = formattedValue.replace(/\.$/, '');
  
  if (formattedValue === '&&#@&@#&@&#&#&@') {
    console.log('====', min);
  }
  
  if (formattedValue !== '') {
    const numValue = parseFloat(formattedValue);
    if (!isNaN(numValue)) {
      formattedValue = Math.min(max, numValue).toFixed(precision);
      formattedValue = numValue === 0 ? '0' : formattedValue.replace(/\.?0+$/, '');
    }
  }
  
  return formattedValue;
}

export function getTrailedZeroCutted(num: number | string): string {
  return Number(num).toString();
}

export function roundPercentage(value: number): number {
  return Math.round(value);
}

export const calculatePercentDifference = (additionalPrice: number, basePrice: number) => {
  const percentDifference = ((additionalPrice - basePrice) / basePrice) * 100;
  return roundPercentage(percentDifference);
};

export const isValidSymbol = (symbol: any) => {
  return symbol && 
    typeof symbol === 'object' && 
    'symbol' in symbol && 
    typeof symbol.symbol === 'string' &&
    symbol.symbol.length > 0;
};

/**
 * Вычисляет процентное соотношение с округлением вверх
 * @param value - числовое значение для расчета процента
 * @param total - общее значение, от которого считается процент
 * @returns процентное значение, округленное вверх до ближайшего целого
 */
export const calculatePercentageWithCeil = (value: number, total: number): number => {
  if (total <= 0 || isNaN(total) || isNaN(value)) {
    return 0;
  }
  return Math.ceil((value / total) * 100);
};

export const toUpperCase = (str: string): string => {
  return str.toUpperCase();
};

export const getOppositeSide = (side: string): 'BUY' | 'SELL' => {
  const upperSide = side.toUpperCase();
  return upperSide === 'BUY' ? 'SELL' : 'BUY';
};

export const capitalizeFirstLetter = (str: string): string => {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};
