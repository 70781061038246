export const getTitle = (type: string) => {
  switch (type) {
  case 'addFunds': return 'Add Funds';
  case 'closeByMarket': return 'Close by market?';
  case 'closeByLimit': return 'Close by limit?';
  case 'openByMarket': return 'Open by market?';
  case 'openByLimit': return 'Open by limit?';
  case 'edit': return 'Edit position';
  case 'share': return 'Share';
  case 'cancel': return 'Cancel position?';
  default: return '';
  }
};
