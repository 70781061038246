import { SmartTrade } from 'pages/manual-trading/trading-terminal/types/smart-trade.types';
import { ModalType } from 'shared/hooks/use-modal-state';
import { CloseGold, Delete, EditIcon, Founds, Share } from 'shared/icons';
import { MenuItem } from '../components/active-trade/types/active-trade.types';

interface UseMenuItemsParams {
  trade: SmartTrade;
  openModal: (params: {
    type: ModalType;
    additionalData?: Record<string, any>;
  }) => void;
  handleCancelTrade: () => Promise<void>;
  handleCloseTradeByMarket: () => Promise<void>;
  handleCloseTradeByLimit: () => Promise<void>;
  handleOpenTradeByMarket: () => Promise<void>;
  handleOpenTradeByLimit: () => Promise<void>;
  handleAddFunds: () => Promise<void>;
}

export const useMenuItems = ({
  trade,
  openModal,
  handleCancelTrade,
  handleCloseTradeByMarket,
  handleCloseTradeByLimit,
  handleOpenTradeByMarket,
  handleOpenTradeByLimit,
  handleAddFunds,
}: UseMenuItemsParams): MenuItem[] => {
  const isBaseOrderCompleted = trade.orders.some(
    order => order.viewType === 'base_order' && order.status === 'completed',
  );
  
  const hasCompletedForcedOrder = trade.orders.some(
    order => order.viewType === 'force_order_step_first' && order.status === 'completed',
  );

  const createMenuItem = (
    key: string,
    label: string,
    icon: React.ReactNode,
    actionFn: () => Promise<void>,
  ): MenuItem => ({
    key,
    label,
    icon,
    onClick: () => openModal({
      type: 'tradeActions',
      additionalData: { 
        actionType: key,
        actionFn, 
      },
    }),
  });

  return isBaseOrderCompleted || hasCompletedForcedOrder
    ? [
      createMenuItem('addFunds', 'Add funds', Founds, handleAddFunds),
      createMenuItem('closeByMarket', 'Close by Market', CloseGold, handleCloseTradeByMarket),
      createMenuItem('closeByLimit', 'Close by Limit', CloseGold, handleCloseTradeByLimit),
      createMenuItem('edit', 'Edit', EditIcon, () => Promise.resolve()),
      createMenuItem('share', 'Share', Share, () => Promise.resolve()),
      createMenuItem('cancel', 'Cancel', Delete, handleCancelTrade),
    ]
    : [
      createMenuItem('addFunds', 'Add funds', Founds, handleAddFunds),
      createMenuItem('openByMarket', 'Open by Market', CloseGold, handleOpenTradeByMarket),
      createMenuItem('openByLimit', 'Open by Limit', CloseGold, handleOpenTradeByLimit),
      createMenuItem('edit', 'Edit', EditIcon, () => Promise.resolve()),
      createMenuItem('share', 'Share', Share, () => Promise.resolve()),
      createMenuItem('cancel', 'Cancel', Delete, handleCancelTrade),
    ];
};
