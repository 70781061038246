import { getPrecisionNumber, sanitizeSymbol, sanitizeSymbolCryptoCom, sanitizeSymbolGate } from 'pages/trading-terminal-page/trading-chart/helpers/common-utils';

interface BaseExchangeSymbol {
    symbol: string;
    status?: string;
    state?: string;
    trade_status?: string;
    inst_type?: string;
    tradable?: boolean;
  }
  
  interface BinanceSymbol extends BaseExchangeSymbol {
    baseAsset: string;
    quoteAsset: string;
    filters: Array<{
      filterType: string;
      tickSize?: string;
      stepSize?: string;
      minPrice?: string;
      maxPrice?: string;
      minQty?: string;
      maxQty?: string;
      minNotional?: string;
      maxNotional?: string;
    }>;
  }
  
  interface BybitSymbol extends BaseExchangeSymbol {
    baseCoin: string;
    quoteCoin: string;
    lotSizeFilter: {
      basePrecision: string;
      quotePrecision: string;
      minOrderQty: string;
      maxOrderQty: string;
      minOrderAmt: string;
      maxOrderAmt: string;
    };
    priceFilter: {
      tickSize: string;
    };
  }
  
  interface OkxSymbol extends BaseExchangeSymbol {
    instId: string;
    baseCcy: string;
    quoteCcy: string;
    lotSz: string;
    tickSz: string;
    minSz: string;
    maxLmtSz: string;
  }
  
  interface GateIOSymbol extends BaseExchangeSymbol {
    id: string;
    base: string;
    quote: string;
    min_quote_amount: string;
    max_quote_amount: string;
    min_base_amount: string;
    amount_precision: number;
    precision: number;
  }
  
  interface CryptoComSymbol extends BaseExchangeSymbol {
    quote_ccy: string;
    quote_decimals: number;
    price_tick_size: string;
    qty_tick_size: string;
    quantity_decimals: number;
    base_ccy: string;
  }
  
  interface BitMartSymbol extends BaseExchangeSymbol {
    quote_currency: string;
    min_buy_amount: number;
    price_max_precision: string;
    quote_increment: string;
    base_currency: string;
    base_min_size: string;
  }
  
  interface HtxSymbol extends BaseExchangeSymbol {
    symbol: string;
    bc: string;
    qc: string;
    pp: number;
    ap: number;
    vp: number;
    minoa: number;
    maxoa: number;
    minov: number;
    smmaxoa: number;
    bmmaxov: number;
    
// - "symbol": "btcusdt" - символ
// - "bc": "btc" - бейз каренси
// - "qc": "usdt" - маркет (квот карренси)
// - "state": "online" - статус пары нас интересуют только "online"
// - "pp": 2 - минимальное изменение цены на графике (знаков после запятой) (chartPrecision или tickSize)
// - "ap": 6 - минимальное изменение units (знаков после запятой) (stepSize)
// - "vp": 8 - минимальное изменение total (знаков после запятой)
// - "minoa": 0.0001 - минимальный размер ордера по Units
// - "maxoa": 1000 - максимальный размер ордера по Units
// - "minov": 10 - минимальный размер ордера по Total (minNotional)
// - "smmaxoa": 5 - максимальный размер SELL market ордера по Units
// - "bmmaxov": 50000 - максимальный размер BUY market ордера по Total
  }
  
  interface CryptoCompareSymbol extends BaseExchangeSymbol {
    INSTRUMENT: string;
    INSTRUMENT_MAPPING: {
      BASE: string;
      QUOTE: string;
    };
    MAPPED_INSTRUMENT: string;
  }
  
  // Общий интерфейс для адаптированных данных
  interface AdaptedSymbol {
    symbol: string;
    baseAsset: string;
    quoteAsset: string;
    baseAssetPrecision: number;
    quoteAssetPrecision: number;
    chartPrecision: number;
    baseCurrencyChartPrecision?: number;
    minNotional?: string | number;
    maxNotional?: string | number;
    lotMax?: string | number;
    lotMin?: string | number;
    priceMin?: string | number;
    priceMax?: string | number;
    [key: string]: any; // для дополнительных специфичных полей
  }
  
// Адаптеры
export function adaptBinanceSymbol(symbol: BinanceSymbol): AdaptedSymbol {
  const priceFilter = symbol.filters.find(el => el.filterType === 'PRICE_FILTER');
  const lotFilter = symbol.filters.find(el => el.filterType === 'LOT_SIZE');
  const notionalFilter = symbol.filters.find(el => el.filterType === 'NOTIONAL');
  const marketLotFilter = symbol.filters?.find(el => el.filterType === 'MARKET_LOT_SIZE');
  
  //@TODO check for Exchange Props, delete unnecessary
  
  return {
    tvFormatSymbol: symbol.symbol,
    symbol: symbol.symbol,
    baseAsset: symbol.baseAsset,
    baseAssetPrecision: getPrecisionNumber(lotFilter?.stepSize || '0'),
    quoteAsset: symbol.quoteAsset,
    quoteAssetPrecision: getPrecisionNumber(priceFilter?.tickSize || '0'),
    priceMin: priceFilter?.minPrice,
    priceMax: priceFilter?.maxPrice,
    minNotional: notionalFilter?.minNotional,
    maxNotional: notionalFilter?.maxNotional,
    lotMax: lotFilter?.maxQty,
    lotMin: lotFilter?.minQty,
    // lotTickSize: lotFilter?.stepSize,
    marketLotMax: marketLotFilter?.maxQty || '99999999',
    marketLotMin: marketLotFilter?.minQty || '99999999',
    chartPrecision: getPrecisionNumber(priceFilter?.tickSize || '0'),
    baseCurrencyChartPrecision: getPrecisionNumber(lotFilter?.stepSize || '0'),
  };
}
  
export function adaptBybitSymbol(symbol: BybitSymbol): AdaptedSymbol {
  return {
    tvFormatSymbol: symbol.symbol,
    symbol: symbol.symbol,
    baseAsset: symbol.baseCoin,
    baseAssetPrecision: getPrecisionNumber(symbol.lotSizeFilter.basePrecision),
    quoteAsset: symbol.quoteCoin,
    quoteAssetPrecision: getPrecisionNumber(symbol.lotSizeFilter.quotePrecision),
    // priceTickSize: symbol.priceFilter.tickSize,
    priceMin: '0',
    priceMax: '999999999',
    minNotional: symbol.lotSizeFilter.minOrderAmt,
    maxNotional: symbol.lotSizeFilter.maxOrderAmt,
    lotMax: symbol.lotSizeFilter.maxOrderQty,
    lotMin: symbol.lotSizeFilter.minOrderQty,
    // lotTickSize: symbol.lotSizeFilter.basePrecision,
    chartPrecision: getPrecisionNumber(symbol.priceFilter.tickSize),
    baseCurrencyChartPrecision: getPrecisionNumber(symbol.lotSizeFilter.basePrecision),
  };
}
  
export function adaptOkxSymbol(symbol: OkxSymbol): AdaptedSymbol {
  return {
    okxSymbol: symbol.instId,
    tvFormatSymbol: symbol.baseCcy + symbol.quoteCcy,
    symbol: sanitizeSymbol(symbol.instId),
    baseAsset: symbol.baseCcy,
    quoteAsset: symbol.quoteCcy,
    baseAssetPrecision: getPrecisionNumber(symbol.lotSz),
    quoteAssetPrecision: getPrecisionNumber(symbol.tickSz),
    //   priceTickSize: symbol.tickSz,
    //   lotTickSize: symbol.lotSz,
    lotMin: symbol.minSz,
    lotMax: symbol.maxLmtSz,
    chartPrecision: getPrecisionNumber(symbol.tickSz),
    baseCurrencyChartPrecision: getPrecisionNumber(symbol.lotSz),
  };
}
  
// {
//   "id": "0DOG_USDT",
//   "base": "0DOG",
//   "quote": "USDT",
//   "fee": "0.2",
//   "min_base_amount": "0.01",
//   "min_quote_amount": "3",
//   "max_quote_amount": "5000000",
//   "amount_precision": 2,
//   "precision": 6,
//   "trade_status": "tradable",
//   "sell_start": 1607313600,
//   "buy_start": 1724238000
// } b

export function adaptGateIOSymbol(symbol: GateIOSymbol): AdaptedSymbol {
  return {
    //@TODO check for sanitizing symbols
    tvFormatSymbol: symbol.base + symbol.quote,
    symbol: sanitizeSymbolGate(symbol.id),
    gateSymbol: symbol.id,
    baseAsset: symbol.base,
    quoteAsset: symbol.quote,
    baseAssetPrecision: symbol.amount_precision,
    quoteAssetPrecision: symbol.precision,
    // priceTickSize: (1 / Math.pow(10, symbol.precision)).toString(),
    // lotTickSize: (1 / Math.pow(10, symbol.amount_precision)).toString(),
    minNotional: symbol.min_quote_amount,
    maxNotional: symbol.max_quote_amount,
    lotMin: symbol.min_base_amount,
    chartPrecision: symbol.precision,
    baseCurrencyChartPrecision: symbol.amount_precision,
  };
}

export function adaptCryptoComSymbol(symbol: CryptoComSymbol): AdaptedSymbol {
  return {
    tvFormatSymbol: symbol.base_ccy + symbol.quote_ccy,
    symbol: sanitizeSymbolCryptoCom(symbol.symbol),
    cryptoComSymbol: symbol.symbol,
    baseAsset: symbol.base_ccy,
    quoteAsset: symbol.quote_ccy,
    baseAssetPrecision: symbol.quantity_decimals,
    quoteAssetPrecision: symbol.quote_decimals,
    // priceTickSize: symbol.price_tick_size,
    // lotTickSize: symbol.qty_tick_size,
    chartPrecision: symbol.quote_decimals,
    minNotional: '0',
    maxNotional: '999999999',
    baseCurrencyChartPrecision: symbol.quantity_decimals,
  };
}

export function adaptBitMartSymbol(symbol: BitMartSymbol): AdaptedSymbol {
  return {
    tvFormatSymbol: symbol.base_currency + symbol.quote_currency,
    symbol: symbol.symbol,
    baseAsset: symbol.base_currency,
    quoteAsset: symbol.quote_currency,
    baseAssetPrecision: getPrecisionNumber(symbol.base_min_size),
    quoteAssetPrecision: Number(symbol.price_max_precision),
    //   priceTickSize: symbol.quote_increment,
    //   lotTickSize: symbol.base_min_size,
    minNotional: symbol.min_buy_amount,
    maxNotional: '999999999',
    chartPrecision: Number(symbol.price_max_precision),
    baseCurrencyChartPrecision: getPrecisionNumber(symbol.base_min_size),
  };
}

export function adaptHtxSymbol(symbol: HtxSymbol): AdaptedSymbol {
  return {
    tvFormatSymbol: symbol.symbol.toUpperCase(),
    symbol: symbol.symbol.toUpperCase(),
    baseAsset: symbol.bc.toUpperCase(),
    quoteAsset: symbol.qc.toUpperCase(),
    chartPrecision: symbol.pp,
    baseAssetPrecision: symbol.ap,
    quoteAssetPrecision: symbol.vp,
    minNotional: symbol.minov,
    maxNotional: symbol.bmmaxov,
    lotMin: symbol.minoa,
    lotMax: symbol.maxoa,
  };
}

export function adaptCryptoCompareSymbol (symbol: CryptoCompareSymbol): AdaptedSymbol {  
  return {
    tvFormatSymbol: symbol.INSTRUMENT_MAPPING.BASE + symbol.INSTRUMENT_MAPPING.QUOTE,
    symbol: symbol.INSTRUMENT_MAPPING.BASE + symbol.INSTRUMENT_MAPPING.QUOTE,
    baseAsset: symbol.INSTRUMENT_MAPPING.BASE,
    quoteAsset: symbol.INSTRUMENT_MAPPING.QUOTE,
    pro_name: symbol.MAPPED_INSTRUMENT,
    chartPrecision: 3,
    baseCurrencyChartPrecision: 8,
    quantityPrecision: 8,
    baseAssetPrecision: 8,
    quoteAssetPrecision: 8,
  };
}

export type {
  BaseExchangeSymbol,
  BinanceSymbol,
  BybitSymbol,
  OkxSymbol,
  GateIOSymbol,
  CryptoComSymbol,
  BitMartSymbol,
  HtxSymbol,
  CryptoCompareSymbol,
  AdaptedSymbol
};
