import { collectFormData, formatDataForConfirmation } from 'pages/manual-trading/trading-terminal/helpers/collect-form-data';
import { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';

interface UseFormSubmissionProps {
    isSkipBaseOrder: boolean;
    exchangeAccountUuid: string;
  }
  
  interface ConfirmationItem {
    title: string;
    columns: string[];
    items: any;
  }
  
export const useFormSubmission = ({
  isSkipBaseOrder,
  exchangeAccountUuid,
}: UseFormSubmissionProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [confirmationData, setConfirmationData] = useState<ConfirmationItem[]>([]);
  const [collectedData, setCollectedData] = useState<any[]>([]);
  
  const {
    clearErrors, trigger, handleSubmit, formState, 
  } = useFormContext();
  
  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    clearErrors('additionalEntryDuplicatePriceError');
    clearErrors('takeProfitDuplicatePriceError');
    trigger();
    event.preventDefault();
    handleSubmit(handleSubmitData)(event);
  };
  
  const handleSubmitData = (data: any) => {
    const collectedFormData = collectFormData(data, isSkipBaseOrder, exchangeAccountUuid);
    setCollectedData(collectedFormData);
    // @TODO: rewrite to clearly show data for modal or for submit
    const formattedData = formatDataForConfirmation(collectedFormData);
    setConfirmationData(formattedData);
    setIsModalVisible(true);
  };
  
  const isFormValidExceptAdditionalEntries = useCallback(() => {    
    const currentErrors = {
      ...formState.errors, 
    };
    delete currentErrors.additionalEntryDuplicatePriceError;
    delete currentErrors.takeProfitDuplicatePriceError;
    return Object.keys(currentErrors).length === 0;
  }, [formState.errors]);
  
  return {
    formState: {
      isModalVisible,
      confirmationData,
      collectedData,
    },
    handlers: {
      handleFormSubmit,
      setIsModalVisible,
      isFormValidExceptAdditionalEntries,
    },
  };
};
