import { SmartTrade } from 'pages/manual-trading/trading-terminal/types/smart-trade.types';
import { currencyFormatter } from 'shared/helpers/currency-formatter';

interface IGetDescriptionParams {
  type: string;
  trade: SmartTrade;
}
export const getDescription = (params: IGetDescriptionParams) => {
  const {
    type, 
    trade,
  } = params;

  const price = currencyFormatter((+trade.currentExchangePrice || 0), 2);
  const quote = trade.quoteSymbol;
  const returnPercent = trade.returnPercent;

  switch (type) {
  case 'addFunds': return 'Add funds';
  case 'closeByMarket':
  case 'closeByLimit': 
    if (trade.sideFirstStep === 'BUY') {
      return `Current price: ${price} ${quote} \n Return: ${Number(trade.returnQuote || 0).toFixed(2)} ${quote} (${Number(returnPercent).toFixed(2)}%)`;
    }

    return `Current price: ${price} ${quote} \n Return: ${Number(trade.returnBase).toFixed(2)} ${trade.baseSymbol} (${Number(returnPercent).toFixed(2)}%)`;
  case 'openByMarket':
  case 'openByLimit': return `Current price: ${price} ${quote}`;
  case 'edit': return 'Edit position';
  case 'cancel': return 'Trade will be canceled on Skyrexio, while assets stay untouched on your exchange account';
  case 'share': return 'Your share link';
  default: return '';
  }
};
